/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter,Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import Users from '../Cruds/Users/index';
import Hospitals from '../Cruds/Hospitals/index';
import Clinics from '../Cruds/Clinics/index';
import Patients from '../Cruds/Patients/index';
import Templates from '../Cruds/Templates/index';
import Forms from '../Cruds/Forms/index';
import Schedules from '../Cruds/Schedules/index';
import Chairs from '../Cruds/Chairs/index';
import Appointments from '../Cruds/Appointments/index';
import Procedures from '../Cruds/Procedures/index';
import TxPlans from '../Cruds/TxPlans/index';
import Treatments from '../Cruds/Treatments/index';
import Prescriptions from '../Cruds/Prescriptions/index';
import Pharmacies from '../Cruds/Pharmacies/index';
import TreatmentPlans from '../Cruds/TreatmentPlans/index';
import Drugs from '../Cruds/Drugs/index';
import Reasons from '../Cruds/Reasons/index';
import Insurances from '../Cruds/Insurances/index';
import Carriers from '../Cruds/Carriers/index';
import Benifits from '../Cruds/Benifits/index';
import Feeschedules from '../Cruds/Feeschedules/index';
import PatientEligibilities from '../Cruds/PatientEligibilities/index';
import ReleaseNotes from '../Cruds/ReleaseNotes/index';
import ProceduresLists from '../Cruds/ProceduresLists/index';
import ChartProcedures from '../Cruds/ChartProcedures/index';
import ClockInAndClockOuts from '../Cruds/ClockInAndClockOuts/index';
import AvailablePrompts from '../Cruds/AvailablePrompts/index';
import AutoNotes from '../Cruds/AutoNotes/index';
import ChartHistories from '../Cruds/ChartHistories/index';
import UploadTeethImages from '../Cruds/UploadTeethImages/index';
import PatientNotes from '../Cruds/PatientNotes/index';
import Referrals from '../Cruds/Referrals/index';
import Accounts from '../Cruds/Accounts/index';
import MakePayments from '../Cruds/MakePayments/index';
import ImageMaintainings from '../Cruds/ImageMaintainings/index';
import PerioCharts from '../Cruds/PerioCharts/index';
import Payments from '../Cruds/Payments/index';
import ClaimManagements from '../Cruds/ClaimManagements/index';
import AnalyticAdmins from '../Cruds/AnalyticAdmins/index';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Verification from '../Landing/LogIn/components/index.jsx';
import GoogleAuthenticator from '../Landing/GoogleLogin/index.jsx';
import VerifyGoogleAuthenticator from '../Landing/GoogleLogin/components/index.jsx';


const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
    <Routes>
      <Route path='/sampleform'
        element={<SampleForm />}
      ></Route>
<Route exact
        path="/employees"
        element={
                roles['Employees'] &&
                  (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
                  ? <Employees/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_employees"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" )
                ? <Employees/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" )
                ? <Employees/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
                ? <Employees  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/users"
        element={
                roles['Users'] &&
                  (roles['Users'].type === "Edit" || roles['Users'].type === "View")
                  ? <Users/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_users"
            element={
              roles['Users'] &&
                (roles['Users'].type === "Edit" )
                ? <Users/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'].type === "Edit" )
                ? <Users/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'].type === "Edit" || roles['Users'].type === "View")
                ? <Users  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/hospitals"
        element={
                roles['Hospitals'] &&
                  (roles['Hospitals'].type === "Edit" || roles['Hospitals'].type === "View")
                  ? <Hospitals/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_hospitals"
            element={
              roles['Hospitals'] &&
                (roles['Hospitals'].type === "Edit" )
                ? <Hospitals/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_hospitals/:id"
            element={
              roles['Hospitals'] &&
                (roles['Hospitals'].type === "Edit" )
                ? <Hospitals/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_hospitals/:id"
            element={
              roles['Hospitals'] &&
                (roles['Hospitals'].type === "Edit" || roles['Hospitals'].type === "View")
                ? <Hospitals  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/clinics"
        element={
                roles['Clinics'] &&
                  (roles['Clinics'].type === "Edit" || roles['Clinics'].type === "View")
                  ? <Clinics/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_clinics"
            element={
              roles['Clinics'] &&
                (roles['Clinics'].type === "Edit" )
                ? <Clinics/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics'].type === "Edit" )
                ? <Clinics/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_clinics/:id"
            element={
              roles['Clinics'] &&
                (roles['Clinics'].type === "Edit" || roles['Clinics'].type === "View")
                ? <Clinics  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/patients"
        element={
                roles['Patients'] &&
                  (roles['Patients'].type === "Edit" || roles['Patients'].type === "View")
                  ? <Patients/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_patients"
            element={
              roles['Patients'] &&
                (roles['Patients'].type === "Edit" )
                ? <Patients/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients'].type === "Edit" )
                ? <Patients/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_patients/:id"
            element={
              roles['Patients'] &&
                (roles['Patients'].type === "Edit" || roles['Patients'].type === "View")
                ? <Patients  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/templates"
        element={
                roles['Templates'] &&
                  (roles['Templates'].type === "Edit" || roles['Templates'].type === "View")
                  ? <Templates/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_templates"
            element={
              roles['Templates'] &&
                (roles['Templates'].type === "Edit" )
                ? <Templates/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates'].type === "Edit" )
                ? <Templates/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_templates/:id"
            element={
              roles['Templates'] &&
                (roles['Templates'].type === "Edit" || roles['Templates'].type === "View")
                ? <Templates  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/forms"
        element={
                roles['Forms'] &&
                  (roles['Forms'].type === "Edit" || roles['Forms'].type === "View")
                  ? <Forms/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_forms"
            element={
              roles['Forms'] &&
                (roles['Forms'].type === "Edit" )
                ? <Forms/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms'].type === "Edit" )
                ? <Forms/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_forms/:id"
            element={
              roles['Forms'] &&
                (roles['Forms'].type === "Edit" || roles['Forms'].type === "View")
                ? <Forms  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/schedules"
        element={
                roles['Schedules'] &&
                  (roles['Schedules'].type === "Edit" || roles['Schedules'].type === "View")
                  ? <Schedules/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_schedules"
            element={
              roles['Schedules'] &&
                (roles['Schedules'].type === "Edit" )
                ? <Schedules/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules'].type === "Edit" )
                ? <Schedules/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_schedules/:id"
            element={
              roles['Schedules'] &&
                (roles['Schedules'].type === "Edit" || roles['Schedules'].type === "View")
                ? <Schedules  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/chairs"
        element={
                roles['Chairs'] &&
                  (roles['Chairs'].type === "Edit" || roles['Chairs'].type === "View")
                  ? <Chairs/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_chairs"
            element={
              roles['Chairs'] &&
                (roles['Chairs'].type === "Edit" )
                ? <Chairs/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs'].type === "Edit" )
                ? <Chairs/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_chairs/:id"
            element={
              roles['Chairs'] &&
                (roles['Chairs'].type === "Edit" || roles['Chairs'].type === "View")
                ? <Chairs  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/appointments"
        element={
                roles['Appointments'] &&
                  (roles['Appointments'].type === "Edit" || roles['Appointments'].type === "View")
                  ? <Appointments/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_appointments"
            element={
              roles['Appointments'] &&
                (roles['Appointments'].type === "Edit" )
                ? <Appointments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments'].type === "Edit" )
                ? <Appointments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_appointments/:id"
            element={
              roles['Appointments'] &&
                (roles['Appointments'].type === "Edit" || roles['Appointments'].type === "View")
                ? <Appointments  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/procedures"
        element={
                roles['Procedures'] &&
                  (roles['Procedures'].type === "Edit" || roles['Procedures'].type === "View")
                  ? <Procedures/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_procedures"
            element={
              roles['Procedures'] &&
                (roles['Procedures'].type === "Edit" )
                ? <Procedures/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures'].type === "Edit" )
                ? <Procedures/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_procedures/:id"
            element={
              roles['Procedures'] &&
                (roles['Procedures'].type === "Edit" || roles['Procedures'].type === "View")
                ? <Procedures  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/txPlans"
        element={
                roles['Tx Plans'] &&
                  (roles['Tx Plans'].type === "Edit" || roles['Tx Plans'].type === "View")
                  ? <TxPlans/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_txPlans"
            element={
              roles['Tx Plans'] &&
                (roles['Tx Plans'].type === "Edit" )
                ? <TxPlans/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_txPlans/:id"
            element={
              roles['Tx Plans'] &&
                (roles['Tx Plans'].type === "Edit" )
                ? <TxPlans/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_txPlans/:id"
            element={
              roles['Tx Plans'] &&
                (roles['Tx Plans'].type === "Edit" || roles['Tx Plans'].type === "View")
                ? <TxPlans  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/treatments"
        element={
                roles['Treatments'] &&
                  (roles['Treatments'].type === "Edit" || roles['Treatments'].type === "View")
                  ? <Treatments/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_treatments"
            element={
              roles['Treatments'] &&
                (roles['Treatments'].type === "Edit" )
                ? <Treatments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments'].type === "Edit" )
                ? <Treatments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_treatments/:id"
            element={
              roles['Treatments'] &&
                (roles['Treatments'].type === "Edit" || roles['Treatments'].type === "View")
                ? <Treatments  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/prescriptions"
        element={
                roles['Prescriptions'] &&
                  (roles['Prescriptions'].type === "Edit" || roles['Prescriptions'].type === "View")
                  ? <Prescriptions/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_prescriptions"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'].type === "Edit" )
                ? <Prescriptions/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'].type === "Edit" )
                ? <Prescriptions/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_prescriptions/:id"
            element={
              roles['Prescriptions'] &&
                (roles['Prescriptions'].type === "Edit" || roles['Prescriptions'].type === "View")
                ? <Prescriptions  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/pharmacies"
        element={
                roles['Pharmacies'] &&
                  (roles['Pharmacies'].type === "Edit" || roles['Pharmacies'].type === "View")
                  ? <Pharmacies/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_pharmacies"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'].type === "Edit" )
                ? <Pharmacies/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'].type === "Edit" )
                ? <Pharmacies/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_pharmacies/:id"
            element={
              roles['Pharmacies'] &&
                (roles['Pharmacies'].type === "Edit" || roles['Pharmacies'].type === "View")
                ? <Pharmacies  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/treatmentPlans"
        element={
                roles['Treatment Plans'] &&
                  (roles['Treatment Plans'].type === "Edit" || roles['Treatment Plans'].type === "View")
                  ? <TreatmentPlans/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_treatmentPlans"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'].type === "Edit" )
                ? <TreatmentPlans/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_treatmentPlans/:id"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'].type === "Edit" )
                ? <TreatmentPlans/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_treatmentPlans/:id"
            element={
              roles['Treatment Plans'] &&
                (roles['Treatment Plans'].type === "Edit" || roles['Treatment Plans'].type === "View")
                ? <TreatmentPlans  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/drugs"
        element={
                roles['Drugs'] &&
                  (roles['Drugs'].type === "Edit" || roles['Drugs'].type === "View")
                  ? <Drugs/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_drugs"
            element={
              roles['Drugs'] &&
                (roles['Drugs'].type === "Edit" )
                ? <Drugs/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs'].type === "Edit" )
                ? <Drugs/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_drugs/:id"
            element={
              roles['Drugs'] &&
                (roles['Drugs'].type === "Edit" || roles['Drugs'].type === "View")
                ? <Drugs  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/reasons"
        element={
                roles['Reasons'] &&
                  (roles['Reasons'].type === "Edit" || roles['Reasons'].type === "View")
                  ? <Reasons/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_reasons"
            element={
              roles['Reasons'] &&
                (roles['Reasons'].type === "Edit" )
                ? <Reasons/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons'].type === "Edit" )
                ? <Reasons/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_reasons/:id"
            element={
              roles['Reasons'] &&
                (roles['Reasons'].type === "Edit" || roles['Reasons'].type === "View")
                ? <Reasons  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/insurances"
        element={
                roles['Insurances'] &&
                  (roles['Insurances'].type === "Edit" || roles['Insurances'].type === "View")
                  ? <Insurances/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_insurances"
            element={
              roles['Insurances'] &&
                (roles['Insurances'].type === "Edit" )
                ? <Insurances/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_insurances/:id"
            element={
              roles['Insurances'] &&
                (roles['Insurances'].type === "Edit" )
                ? <Insurances/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_insurances/:id"
            element={
              roles['Insurances'] &&
                (roles['Insurances'].type === "Edit" || roles['Insurances'].type === "View")
                ? <Insurances  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/carriers"
        element={
                roles['Carriers'] &&
                  (roles['Carriers'].type === "Edit" || roles['Carriers'].type === "View")
                  ? <Carriers/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_carriers"
            element={
              roles['Carriers'] &&
                (roles['Carriers'].type === "Edit" )
                ? <Carriers/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_carriers/:id"
            element={
              roles['Carriers'] &&
                (roles['Carriers'].type === "Edit" )
                ? <Carriers/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_carriers/:id"
            element={
              roles['Carriers'] &&
                (roles['Carriers'].type === "Edit" || roles['Carriers'].type === "View")
                ? <Carriers  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/benifits"
        element={
                roles['Benifits'] &&
                  (roles['Benifits'].type === "Edit" || roles['Benifits'].type === "View")
                  ? <Benifits/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_benifits"
            element={
              roles['Benifits'] &&
                (roles['Benifits'].type === "Edit" )
                ? <Benifits/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_benifits/:id"
            element={
              roles['Benifits'] &&
                (roles['Benifits'].type === "Edit" )
                ? <Benifits/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_benifits/:id"
            element={
              roles['Benifits'] &&
                (roles['Benifits'].type === "Edit" || roles['Benifits'].type === "View")
                ? <Benifits  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/feeschedules"
        element={
                roles['Feeschedules'] &&
                  (roles['Feeschedules'].type === "Edit" || roles['Feeschedules'].type === "View")
                  ? <Feeschedules/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_feeschedules"
            element={
              roles['Feeschedules'] &&
                (roles['Feeschedules'].type === "Edit" )
                ? <Feeschedules/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_feeschedules/:id"
            element={
              roles['Feeschedules'] &&
                (roles['Feeschedules'].type === "Edit" )
                ? <Feeschedules/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_feeschedules/:id"
            element={
              roles['Feeschedules'] &&
                (roles['Feeschedules'].type === "Edit" || roles['Feeschedules'].type === "View")
                ? <Feeschedules  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/patientEligibilities"
        element={
                roles['PatientEligibilities'] &&
                  (roles['PatientEligibilities'].type === "Edit" || roles['PatientEligibilities'].type === "View")
                  ? <PatientEligibilities/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_patientEligibilities"
            element={
              roles['PatientEligibilities'] &&
                (roles['PatientEligibilities'].type === "Edit" )
                ? <PatientEligibilities/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_patientEligibilities/:id"
            element={
              roles['PatientEligibilities'] &&
                (roles['PatientEligibilities'].type === "Edit" )
                ? <PatientEligibilities/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_patientEligibilities/:id"
            element={
              roles['PatientEligibilities'] &&
                (roles['PatientEligibilities'].type === "Edit" || roles['PatientEligibilities'].type === "View")
                ? <PatientEligibilities  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/releaseNotes"
        element={
                roles['ReleaseNotes'] &&
                  (roles['ReleaseNotes'].type === "Edit" || roles['ReleaseNotes'].type === "View")
                  ? <ReleaseNotes/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_releaseNotes"
            element={
              roles['ReleaseNotes'] &&
                (roles['ReleaseNotes'].type === "Edit" )
                ? <ReleaseNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_releaseNotes/:id"
            element={
              roles['ReleaseNotes'] &&
                (roles['ReleaseNotes'].type === "Edit" )
                ? <ReleaseNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_releaseNotes/:id"
            element={
              roles['ReleaseNotes'] &&
                (roles['ReleaseNotes'].type === "Edit" || roles['ReleaseNotes'].type === "View")
                ? <ReleaseNotes  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/proceduresLists"
        element={
                roles['ProceduresLists'] &&
                  (roles['ProceduresLists'].type === "Edit" || roles['ProceduresLists'].type === "View")
                  ? <ProceduresLists/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_proceduresLists"
            element={
              roles['ProceduresLists'] &&
                (roles['ProceduresLists'].type === "Edit" )
                ? <ProceduresLists/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_proceduresLists/:id"
            element={
              roles['ProceduresLists'] &&
                (roles['ProceduresLists'].type === "Edit" )
                ? <ProceduresLists/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_proceduresLists/:id"
            element={
              roles['ProceduresLists'] &&
                (roles['ProceduresLists'].type === "Edit" || roles['ProceduresLists'].type === "View")
                ? <ProceduresLists  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/chartProcedures"
        element={
                roles['ChartProcedures'] &&
                  (roles['ChartProcedures'].type === "Edit" || roles['ChartProcedures'].type === "View")
                  ? <ChartProcedures/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_chartProcedures"
            element={
              roles['ChartProcedures'] &&
                (roles['ChartProcedures'].type === "Edit" )
                ? <ChartProcedures/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_chartProcedures/:id"
            element={
              roles['ChartProcedures'] &&
                (roles['ChartProcedures'].type === "Edit" )
                ? <ChartProcedures/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_chartProcedures/:id"
            element={
              roles['ChartProcedures'] &&
                (roles['ChartProcedures'].type === "Edit" || roles['ChartProcedures'].type === "View")
                ? <ChartProcedures  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/clockInAndClockOuts"
        element={
                roles['ClockIn And ClockOuts'] &&
                  (roles['ClockIn And ClockOuts'].type === "Edit" || roles['ClockIn And ClockOuts'].type === "View")
                  ? <ClockInAndClockOuts/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_clockInAndClockOuts"
            element={
              roles['ClockIn And ClockOuts'] &&
                (roles['ClockIn And ClockOuts'].type === "Edit" )
                ? <ClockInAndClockOuts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_clockInAndClockOuts/:id"
            element={
              roles['ClockIn And ClockOuts'] &&
                (roles['ClockIn And ClockOuts'].type === "Edit" )
                ? <ClockInAndClockOuts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_clockInAndClockOuts/:id"
            element={
              roles['ClockIn And ClockOuts'] &&
                (roles['ClockIn And ClockOuts'].type === "Edit" || roles['ClockIn And ClockOuts'].type === "View")
                ? <ClockInAndClockOuts  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/availablePrompts"
        element={
                roles['Available Prompts'] &&
                  (roles['Available Prompts'].type === "Edit" || roles['Available Prompts'].type === "View")
                  ? <AvailablePrompts/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_availablePrompts"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts'].type === "Edit" )
                ? <AvailablePrompts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_availablePrompts/:id"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts'].type === "Edit" )
                ? <AvailablePrompts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_availablePrompts/:id"
            element={
              roles['Available Prompts'] &&
                (roles['Available Prompts'].type === "Edit" || roles['Available Prompts'].type === "View")
                ? <AvailablePrompts  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/autoNotes"
        element={
                roles['Auto Notes'] &&
                  (roles['Auto Notes'].type === "Edit" || roles['Auto Notes'].type === "View")
                  ? <AutoNotes/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_autoNotes"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes'].type === "Edit" )
                ? <AutoNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_autoNotes/:id"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes'].type === "Edit" )
                ? <AutoNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_autoNotes/:id"
            element={
              roles['Auto Notes'] &&
                (roles['Auto Notes'].type === "Edit" || roles['Auto Notes'].type === "View")
                ? <AutoNotes  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/chartHistories"
        element={
                roles['Chart  Histories'] &&
                  (roles['Chart  Histories'].type === "Edit" || roles['Chart  Histories'].type === "View")
                  ? <ChartHistories/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_chartHistories"
            element={
              roles['Chart  Histories'] &&
                (roles['Chart  Histories'].type === "Edit" )
                ? <ChartHistories/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_chartHistories/:id"
            element={
              roles['Chart  Histories'] &&
                (roles['Chart  Histories'].type === "Edit" )
                ? <ChartHistories/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_chartHistories/:id"
            element={
              roles['Chart  Histories'] &&
                (roles['Chart  Histories'].type === "Edit" || roles['Chart  Histories'].type === "View")
                ? <ChartHistories  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/uploadTeethImages"
        element={
                roles['Upload Teeth Images'] &&
                  (roles['Upload Teeth Images'].type === "Edit" || roles['Upload Teeth Images'].type === "View")
                  ? <UploadTeethImages/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_uploadTeethImages"
            element={
              roles['Upload Teeth Images'] &&
                (roles['Upload Teeth Images'].type === "Edit" )
                ? <UploadTeethImages/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_uploadTeethImages/:id"
            element={
              roles['Upload Teeth Images'] &&
                (roles['Upload Teeth Images'].type === "Edit" )
                ? <UploadTeethImages/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_uploadTeethImages/:id"
            element={
              roles['Upload Teeth Images'] &&
                (roles['Upload Teeth Images'].type === "Edit" || roles['Upload Teeth Images'].type === "View")
                ? <UploadTeethImages  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/patientNotes"
        element={
                roles['Patient Notes'] &&
                  (roles['Patient Notes'].type === "Edit" || roles['Patient Notes'].type === "View")
                  ? <PatientNotes/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_patientNotes"
            element={
              roles['Patient Notes'] &&
                (roles['Patient Notes'].type === "Edit" )
                ? <PatientNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_patientNotes/:id"
            element={
              roles['Patient Notes'] &&
                (roles['Patient Notes'].type === "Edit" )
                ? <PatientNotes/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_patientNotes/:id"
            element={
              roles['Patient Notes'] &&
                (roles['Patient Notes'].type === "Edit" || roles['Patient Notes'].type === "View")
                ? <PatientNotes  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/referrals"
        element={
                roles['Referrals'] &&
                  (roles['Referrals'].type === "Edit" || roles['Referrals'].type === "View")
                  ? <Referrals/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_referrals"
            element={
              roles['Referrals'] &&
                (roles['Referrals'].type === "Edit" )
                ? <Referrals/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_referrals/:id"
            element={
              roles['Referrals'] &&
                (roles['Referrals'].type === "Edit" )
                ? <Referrals/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_referrals/:id"
            element={
              roles['Referrals'] &&
                (roles['Referrals'].type === "Edit" || roles['Referrals'].type === "View")
                ? <Referrals  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/accounts"
        element={
                roles['Accounts'] &&
                  (roles['Accounts'].type === "Edit" || roles['Accounts'].type === "View")
                  ? <Accounts/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_accounts"
            element={
              roles['Accounts'] &&
                (roles['Accounts'].type === "Edit" )
                ? <Accounts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts'].type === "Edit" )
                ? <Accounts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_accounts/:id"
            element={
              roles['Accounts'] &&
                (roles['Accounts'].type === "Edit" || roles['Accounts'].type === "View")
                ? <Accounts  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/makePayments"
        element={
                roles['MakePayments'] &&
                  (roles['MakePayments'].type === "Edit" || roles['MakePayments'].type === "View")
                  ? <MakePayments/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_makePayments"
            element={
              roles['MakePayments'] &&
                (roles['MakePayments'].type === "Edit" )
                ? <MakePayments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_makePayments/:id"
            element={
              roles['MakePayments'] &&
                (roles['MakePayments'].type === "Edit" )
                ? <MakePayments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_makePayments/:id"
            element={
              roles['MakePayments'] &&
                (roles['MakePayments'].type === "Edit" || roles['MakePayments'].type === "View")
                ? <MakePayments  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/imageMaintainings"
        element={
                roles['Image Maintainings'] &&
                  (roles['Image Maintainings'].type === "Edit" || roles['Image Maintainings'].type === "View")
                  ? <ImageMaintainings/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_imageMaintainings"
            element={
              roles['Image Maintainings'] &&
                (roles['Image Maintainings'].type === "Edit" )
                ? <ImageMaintainings/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_imageMaintainings/:id"
            element={
              roles['Image Maintainings'] &&
                (roles['Image Maintainings'].type === "Edit" )
                ? <ImageMaintainings/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_imageMaintainings/:id"
            element={
              roles['Image Maintainings'] &&
                (roles['Image Maintainings'].type === "Edit" || roles['Image Maintainings'].type === "View")
                ? <ImageMaintainings  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/perioCharts"
        element={
                roles['Perio Charts'] &&
                  (roles['Perio Charts'].type === "Edit" || roles['Perio Charts'].type === "View")
                  ? <PerioCharts/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_perioCharts"
            element={
              roles['Perio Charts'] &&
                (roles['Perio Charts'].type === "Edit" )
                ? <PerioCharts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_perioCharts/:id"
            element={
              roles['Perio Charts'] &&
                (roles['Perio Charts'].type === "Edit" )
                ? <PerioCharts/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_perioCharts/:id"
            element={
              roles['Perio Charts'] &&
                (roles['Perio Charts'].type === "Edit" || roles['Perio Charts'].type === "View")
                ? <PerioCharts  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/payments"
        element={
                roles['Payments'] &&
                  (roles['Payments'].type === "Edit" || roles['Payments'].type === "View")
                  ? <Payments/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_payments"
            element={
              roles['Payments'] &&
                (roles['Payments'].type === "Edit" )
                ? <Payments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_payments/:id"
            element={
              roles['Payments'] &&
                (roles['Payments'].type === "Edit" )
                ? <Payments/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_payments/:id"
            element={
              roles['Payments'] &&
                (roles['Payments'].type === "Edit" || roles['Payments'].type === "View")
                ? <Payments  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/claimManagements"
        element={
                roles['Claim Managements'] &&
                  (roles['Claim Managements'].type === "Edit" || roles['Claim Managements'].type === "View")
                  ? <ClaimManagements/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_claimManagements"
            element={
              roles['Claim Managements'] &&
                (roles['Claim Managements'].type === "Edit" )
                ? <ClaimManagements/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_claimManagements/:id"
            element={
              roles['Claim Managements'] &&
                (roles['Claim Managements'].type === "Edit" )
                ? <ClaimManagements/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_claimManagements/:id"
            element={
              roles['Claim Managements'] &&
                (roles['Claim Managements'].type === "Edit" || roles['Claim Managements'].type === "View")
                ? <ClaimManagements  />  
                : <ErrorNotFound/>
            }
          />
<Route exact
        path="/analyticAdmins"
        element={
                roles['Analytic Admins'] &&
                  (roles['Analytic Admins'].type === "Edit" || roles['Analytic Admins'].type === "View")
                  ? <AnalyticAdmins/>
                    : <ErrorNotFound/>
                  } />

            <Route
            path="/create_analyticAdmins"
            element={
              roles['Analytic Admins'] &&
                (roles['Analytic Admins'].type === "Edit" )
                ? <AnalyticAdmins/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/edit_analyticAdmins/:id"
            element={
              roles['Analytic Admins'] &&
                (roles['Analytic Admins'].type === "Edit" )
                ? <AnalyticAdmins/>  
                : <ErrorNotFound/>
            }
          />

            <Route
            path="/view_analyticAdmins/:id"
            element={
              roles['Analytic Admins'] &&
                (roles['Analytic Admins'].type === "Edit" || roles['Analytic Admins'].type === "View")
                ? <AnalyticAdmins  />  
                : <ErrorNotFound/>
            }
          />

      {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

      {/* <Route path="/settings" element={Settings} /> */}
      {/* <Route path="/adminSettings" element={AdminSettings} /> */}
      <Route
        path="/adminSettings"
        element={
          config.displaySettings
            ? <AdminSettings />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/uploads"
        element={
          roles["Upload Histories"] &&
            (roles["Upload Histories"]?.type === "Edit" || roles["Upload Histories"]?.type === "View")
            ? <Uploads />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/activities"
        element={
          roles["Activities"] &&
            (roles["Activities"]?.type === "Edit" || roles["Activities"]?.type === "View")
            ? <Activities />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/roles"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] ?.type=== "Edit")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit")
            ? <Roles  />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/emailStatuses"
        element={
          roles["Email Statuses"] &&
            (roles["Email Statuses"]?.type === "Edit" || roles["Email Statuses"]?.type === "View")
            ? <EmailStatus />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit")
            ?<EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit")
            ?  <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
    </Routes >
    <Outlet />
    </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />
    
  </Routes>
  
);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/two_step_verification" element={<Verification />} />
        <Route path="/authenticator" element={<GoogleAuthenticator />} />
        <Route path="/verify_authenticator" element={<VerifyGoogleAuthenticator />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />}  />
        
        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
