const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  users:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_users_bulk_upload_file.csv",
  hospitals:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_hospitals_bulk_upload_file.csv",
  clinics:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_clinics_bulk_upload_file.csv",
  patients:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_patients_bulk_upload_file.csv",
  templates:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_templates_bulk_upload_file.csv",
  forms:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_forms_bulk_upload_file.csv",
  schedules:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_schedules_bulk_upload_file.csv",
  chairs:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_chairs_bulk_upload_file.csv",
  appointments:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_appointments_bulk_upload_file.csv",
  procedures:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_procedures_bulk_upload_file.csv",
  txplans:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_txplans_bulk_upload_file.csv",
  treatments:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_treatments_bulk_upload_file.csv",
  prescriptions:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_prescriptions_bulk_upload_file.csv",
  pharmacies:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_pharmacies_bulk_upload_file.csv",
  treatmentplans:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_treatmentplans_bulk_upload_file.csv",
  drugs:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_drugs_bulk_upload_file.csv",
  reasons:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_reasons_bulk_upload_file.csv",
  insurances:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_insurances_bulk_upload_file.csv",
  carriers:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_carriers_bulk_upload_file.csv",
  benifits:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_benifits_bulk_upload_file.csv",
  feeschedules:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_feeschedules_bulk_upload_file.csv",
  patienteligibilities:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_patienteligibilities_bulk_upload_file.csv",
  releasenotes:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_releasenotes_bulk_upload_file.csv",
  procedureslists:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_procedureslists_bulk_upload_file.csv",
  chartprocedures:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_chartprocedures_bulk_upload_file.csv",
  clockinandclockouts:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_clockinandclockouts_bulk_upload_file.csv",
  availableprompts:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_availableprompts_bulk_upload_file.csv",
  autonotes:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_autonotes_bulk_upload_file.csv",
  charthistories:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_charthistories_bulk_upload_file.csv",
  uploadteethimages:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_uploadteethimages_bulk_upload_file.csv",
  patientnotes:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_patientnotes_bulk_upload_file.csv",
  referrals:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_referrals_bulk_upload_file.csv",
  accounts:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_accounts_bulk_upload_file.csv",
  makepayments:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_makepayments_bulk_upload_file.csv",
  imagemaintainings:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_imagemaintainings_bulk_upload_file.csv",
  periocharts:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_periocharts_bulk_upload_file.csv",
  payments:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_payments_bulk_upload_file.csv",
  claimmanagements:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_claimmanagements_bulk_upload_file.csv",
  analyticadmins:
    "http://api.practicemanagement.dosystemsinc.com/images/sample_analyticadmins_bulk_upload_file.csv",
};
export default configSampleFiles;
