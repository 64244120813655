import React, { useRef } from "react";
import { FileUpload } from 'primereact/fileupload';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import { Link } from 'react-router-dom';

const DoFileUpload = (props) => {
    const fileInputRef = useRef(null);

    const id = props.id ? props.id : props.name;
    let label = props.label ? props.label : '';
    let newFiles = props.field.value;
    let isMultiple = props.isMultiple ? props.isMultiple : false;
    let maxFileSize = props.maxFileSize ? props.maxFileSize : 2097152; // 2 MB
    let type = props.type;
    const maxFiles = 5;

    const onSelect = (e) => {
        const selectedFiles = isMultiple ? e.files : e.files[0];
        const oversizedFiles = [e.files[0]].filter(file => file.size > maxFileSize);

        if (oversizedFiles.length > 0) {
            const bytesPerMB = 1024 * 1024;
            showToasterMessage(`File size should be less than ${maxFileSize / bytesPerMB} MB`, 'error');
            clearFileUpload();
        } else {
            uploadFilesToServer(selectedFiles);
        }
    }

    const upload = (e) => {
        const files = e.files;
        if (files.length > maxFiles) {
            showToasterMessage(`You can only upload ${maxFiles} files`, 'error')
            const fileInput = document.querySelector('.p-fileupload-content');
            if (fileInput) {
                fileInput.style.display = 'none';
            }
        } else {
            uploadFilesToServer(isMultiple ? e.files : e.files[0]);
        }
    }

    const uploadFilesToServer = (data) => {
        fetchMethodRequest('POST', props.url, data, 'upload', isMultiple)
            .then(async (response) => {
                if (response && response.respCode) {
                    let fileName = response?.fileName;
                    //Setting field value in form
                    if (props.field && !isMultiple) {
                        props.field.onChange([fileName[0].name])
                    } else {
                        let newFiles = fileName.map(file => file.name);
                        let existingFiles = props.field.value || [];
                        let allFiles = [...existingFiles, ...newFiles];
                        if (props.field) {
                            props.field.onChange(allFiles)
                        }
                        clearFileUpload();
                    }
                    if (props.type === 'profile') {
                        let data = JSON.parse(localStorage.getItem('loginCredentials'))
                        data.photo = fileName;
                        localStorage.setItem('loginCredentials', JSON.stringify(data))
                    }
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    showToasterMessage('upload failed')
                }
            }).catch((err) => {
                return err;
            });
    }

    const checkImg = (img) => {
        if (img) {
            return config.imageFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

    const deleteFile = (img) => {
        if (img) {
            let allFiles = newFiles.filter(file => file !== img)
            props.field.onChange(allFiles);
            clearFileUpload();
        }
    };

    const clearFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.clear()
        }
    };


    return (
        <div className='d-flex flex-column'>
            <div className="flex align-center">
                <label htmlFor={id} className="text-capitalize">{label}</label>
            </div>
            <FileUpload
                ref={fileInputRef}
                mode={isMultiple ? 'advanced' : 'basic'}
                name={props.name ? props.name : 'file'}
                accept={props.acceptType ? props.acceptType : '*'}
                maxFileSize={maxFileSize}
                onSelect={!isMultiple ? onSelect : ''}
                customUpload
                uploadHandler={upload}
                multiple={isMultiple ? isMultiple : false}
                chooseLabel={props.label ? props.label : 'Choose File'}
            />
            <div>
                {newFiles && newFiles.length > 0 && (
                    <div className="d-flex flex-wrap">
                        {newFiles.map(img => (
                            <div className="row w-50" >
                                <div className="col-7 my-2">
                                    {props.type === 'profile' ?
                                        (<Link to={`${config.profileUrl}${img}`} target="_blank">
                                            <img src={config.profileUrl + img} style={{ width: '100%', height: '100%' }} alt="" />
                                        </Link>) :
                                        checkImg(img) ? (
                                            <Link to={`${config.imgUrl}${type.toLowerCase()}/${img}`} target="_blank">
                                                <img src={config.imgUrl + `${type.toLowerCase()}/` + img} style={{ width: '80%' }} alt="" />
                                            </Link>
                                        ) : (
                                            <a href={`${config.imgUrl}${type.toLowerCase()}/${img}`} target='_blank' >
                                                <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                                            </a>
                                        )}
                                </div>
                                <div className="col-4 my-3">
                                    <button className="btn text-danger" type='button' onClick={() => deleteFile(img)} style={{ padding: '5px 10px' }}>
                                        <i className="pi pi-trash" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState?.invalid ? props.errors[props.name]?.message : ''}</small>
         </div>
    );
};
export default DoFileUpload;