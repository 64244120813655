import React,{ useEffect, useState } from 'react';
import DataTable from '../../CommonDataTable/DataTable';
import { useParams } from 'react-router';
import apiCalls from '../../../../config/apiCalls'
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

import fetchMethodRequest from "../../../../config/service"
import RolePermissions from '../../CommonModals/Permissions';
// config file
import config from '../../../../config/config';

const EmailTemplate = (props) => {

  
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(window.location.href.includes("edit"));
  const [addSelectedRecord, setAddSelectedRecord] = useState(window.location.href.includes("create"));
  const [viewSelectedRecord, setViewSelectedRecord] = useState(window.location.href.includes("view"));
  const params = useParams();

  useEffect(()=> {
		let screenPermissions = RolePermissions.screenPermissions('Email Templates');
		if (screenPermissions) {		
			setRolePermission(screenPermissions);
		}

	},[]);

  const setData = (props) => {
    let rowData = {}
 
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id)
      return rowData
    }
  }
  const getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": "Link",
        "field": "name",
        "header": "Name",
        "filter": true,
        "sortable": true,
        displayInSettings: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: 'subject',
        header: 'Subject',
        filter: true,
        sortable: false,
        placeholder: 'search',
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 60,
      //   "fieldType": "Actions",
      //   "field": "Actions",
      //   "header": "Actions",
      //   "filter": false,
      //   "sortable": false,
      //   displayInSettings: true,
      // },
      // {
      //   textAlign: 'center',
      //   width: 80,
      //   field: 'created',
      //   header: 'Created',
      //   filter: true,
      //   sortable: false,
      //   placeholder: config.dateDayMonthFormat
      // },
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"mobile":true,
      "displayInSettings":true},
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":false,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"tDisplay":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":false,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"tDisplay":true}, 
    ];
    data.push( {
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    })
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Inactive',
        value: 'Inactive'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
    ];
    return ([
      {
        required: true,
        value: '',
        type: 'text',
        name: 'name',
        label: 'Name',
        id: 'name',
        placeholder: 'Name'
      },
      {
        required: true,
        value: '',
        type: 'text',
        name: 'subject',
        label: 'Subject',
        id: 'subject',
        placeholder: 'Subject'
      },

      // {
      //   required: true,
      //   value: '',
      //   type: 'text',
      //   name: 'content',
      //   label: 'Content',
      //   id: 'content',
      //   placeholder: 'Content'
      // },

      {
        required: true,
        value: '',
        type: 'ckeditor',
        name: 'templateText',
        label: 'Email Template',
        id: 'emailTemplate',
        placeholder: 'name'
      },
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":true,"show":true,"addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true,"isAddFormHidden":true,"isEditFormHidden":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":true,"show":true,"addFormOrder":7,"editFormOrder":7,"mobile":true,"displayInSettings":true,"isAddFormHidden":true,
      "isEditFormHidden":true},
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":false,"show":true,"addFormOrder":6,"editFormOrder":6,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"isAddFormHidden":false,"isEditFormHidden":false},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":false,"show":true,"addFormOrder":7,"editFormOrder":7,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"isAddFormHidden":false,"isEditFormHidden":false},
    ]);
  }
  const getMobileTableFields = () => {
    let statusTypes = [
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Inactive',
        value: 'Inactive'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
    ];
    return ([
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        required: true,
        value: '',
        type: 'text',
        name: 'name',
        label: 'Name',
        id: 'name',
        placeholder: 'Name'
      },
      {
        required: true,
        value: '',
        type: 'text',
        name: 'subject',
        label: 'Subject',
        id: 'subject',
        placeholder: 'Subject'
      },
      {
        required: true,
        value: '',
        type: 'ckeditor',
        name: 'templateText',
        label: 'Email Template',
        id: 'emailTemplate',
        placeholder: 'name'
      },
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"mobile":true,
      "displayInSettings":true},
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":false,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"tDisplay":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","globalSearchField":"true","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":false,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"isFieldRequired":"false","mobile":true,"displayInSettings":true,"tDisplay":true},
    ]);
  };

  
 const saveDataToServer = async (item, field, value) => {
    let body = item[0]
    body[field] = value
    let userBody = Object.assign({}, body);
   
    if (body) {
      let method, apiUrl;
      method = 'PUT';
      apiUrl = `templates/${body._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');

          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
         
        }).catch((err) => {
          return err;
        });
    } else {
      return;
    }
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false
  const actionTypes = [{
    'name': 'Delete',
    "options": [
      { 'label': 'Delete', 'value': 'Delete', 'show': rolePermission && rolePermission == "Edit" ? true : false, "multiple": true, },
    ]
  },
  ]
  
    return (
      <div>
        <DataTable
          {...props}
          MobileTableFields={getMobileTableFields}
          getTableFields={getTableFields}
          formFields={getFormFields}
          actionsTypes={actionTypes}
          exportRequired={true}
          printRequired={false}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          dateSearchRequired={undefined}
          searchInDateRangeField={"undefined"}
          preview={true}
          globalSearchFieldName='subject'
          globalSearch={"name/subject/created/updated"}
          type='Email Templates'
          displayName='Email Templates'
          apiResponseKey='templates'
          apiUrl={"templates"}
          routeTo='templates'
          displayViewOfForm='screen'
          selectedId={params.id}
           
           
           
        />
      </div>
    );
  
}

export default EmailTemplate;