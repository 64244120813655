import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Treatments from './components/Treatments';

const treatments = (props,{ t }) => (
  <Container>
    <Treatments {...props} />
  </Container>
);

treatments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(treatments);
