import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImageMaintainings from './components/ImageMaintainings';

const imageMaintainings = (props,{ t }) => (
  <Container>
    <ImageMaintainings {...props} />
  </Container>
);

imageMaintainings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(imageMaintainings);
