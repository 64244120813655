import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MakePayments from './components/MakePayments';

const makePayments = (props,{ t }) => (
  <Container>
    <MakePayments {...props} />
  </Container>
);

makePayments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(makePayments);
