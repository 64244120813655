import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AutoNotes from './components/AutoNotes';

const autoNotes = (props,{ t }) => (
  <Container>
    <AutoNotes {...props} />
  </Container>
);

autoNotes.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(autoNotes);
