import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Accounts from './components/Accounts';

const accounts = (props,{ t }) => (
  <Container>
    <Accounts {...props} />
  </Container>
);

accounts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(accounts);
