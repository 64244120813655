import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PerioCharts from './components/PerioCharts';

const perioCharts = (props,{ t }) => (
  <Container>
    <PerioCharts {...props} />
  </Container>
);

perioCharts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(perioCharts);
