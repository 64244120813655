import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ChartProcedures from './components/ChartProcedures';

const chartProcedures = (props,{ t }) => (
  <Container>
    <ChartProcedures {...props} />
  </Container>
);

chartProcedures.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(chartProcedures);
