import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClaimManagements from './components/ClaimManagements';

const claimManagements = (props,{ t }) => (
  <Container>
    <ClaimManagements {...props} />
  </Container>
);

claimManagements.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(claimManagements);
